import React, {Component} from 'react';

import '../assets/scss/partner.scss';
import LazyImage from "./lazyImage.class";

import {ReactComponent as Left} from '../assets/img/svg/left.svg';
import {ReactComponent as Right} from '../assets/img/svg/right.svg';

import {go, goTo, shuffle} from './sliderFunctions';
import marked from "./markdown";


let timer = null;
let subTimer = null;

class PartnerSlide extends Component {

    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            subheadline: '',
            images: [],
            current: 0
        };
    }

    getImages(images) {
        let i = shuffle(images);
        let groups = [];

        let g = 0;

        if (i.length) {
            i.forEach((img, key) => {

                if (!groups[g]) {
                    groups[g] = [];
                }

                groups[g].push(img);

                if (window.innerWidth >= 600) {
                    if (key % 3 === 2) {
                        g++;
                    }
                } else if (window.innerWidth <= 600) {
                    g++;
                }
            });
        }

        return groups;

    }

    render() {
        let left = '-100%';

        if (this.state.images.length <= 1) {
            left = 0;
        }

        let imageGroups = null;

        if (this.state.images.length) {
            imageGroups = this.state.images;
        }

        return (
            <>

                <div className={'sliderOuter'} id={this.props.id}>
                    {(!!imageGroups && imageGroups.length > 1) &&
                        <>
                            <div className={'prevSlide'} onClick={() => go('prev', this.props.id, timer, subTimer, this)} title={'Zurück'}>
                                <Left />
                            </div>
                            <div className={'nextSlide'} onClick={() => go('next', this.props.id, timer, subTimer, this)} title={'Weiter'}>
                                <Right />
                            </div>
                        </>
                    }

                    <div className={'slideBox'}>

                        <div className={'slides'} style={{left:left}}>
                            {
                                (!!imageGroups && imageGroups.length > 0) &&
                                imageGroups.map((images, key) => {

                                    let className = '';

                                    if (key % 2 === 0) {
                                        className = ' left';
                                    }

                                    if (key === 0) {
                                        className += ' current';
                                    } else if ( key === this.state.images.length-1) {
                                        className += ' prev';
                                    } else if (key === 1) {
                                        className += ' next';
                                    }

                                    return (
                                        <div key={'slide_' + key} className={'slide' + className}>
                                            <div className={'images'}>
                                                <div className={'imgBox'}>
                                                    {
                                                        images.map((i, k) => {
                                                            return (
                                                                <div key={'large' + k} className={'img'}>
                                                                    <a href={i['link']} className={'image'} target={'_blank'} title={'Zur Webseite'}>
                                                                        <LazyImage key={'big' + k} dkey={'slider_img_' + k} alt={'img' + k} src={'image.php?file=' + (!!i ? i['logo'].replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=405&width=720'} forceLoad={this.props.forceLoad} />
                                                                    </a>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>


                    {(!!imageGroups && imageGroups.length > 1) &&
                        <div className={'bubbleBox'}>
                            {
                                (!!imageGroups && imageGroups.length > 1) &&
                                imageGroups.map((slide, key) => {
                                        return <div key={'bubble_' + key} className={'bubble' + (this.state.current === key ? ' current' : '')} onClick={() => goTo(key, this.props.id, timer, subTimer, this)} />;
                                    }
                                )
                            }
                        </div>
                    }
                </div>
            </>
        );
    }

    componentDidMount() {
        this.setState({
            images: this.getImages(this.props.images),
        });
    }
}


class Partner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            subheadline: '',
            current: !!props.data && !!props.data.tabs ? Object.keys(props.data.tabs)[0] : null,
        };
    }

    show(key) {
        this.setState({
            current: key
        });
    }

    switchSelect(e) {
        let target = e.target;

        this.setState({
            current: target.value
        });
    }

    render() {

        let groups = !!this.props.data && !!this.props.data.tabs ? this.props.data.tabs : null;

        return (
            <div className={'partnerBoxElm'} id={this.props.id}>

                <div className={'headlines'}>
                    <h2>
                        {this.state.headline}
                    </h2>

                    <h3>
                        {this.state.subheadline}
                    </h3>
                </div>

                {(Object.keys(groups).length > 0) &&
                    <>
                        <div className={'tabSelect'}>
                            <select onChange={e => this.switchSelect(e)} value={this.state.current}>
                                {groups &&
                                    Object.keys(groups).map(e => {
                                        return <option value={e} key={'tab_' + e}>{e.replace('<br>', '')}</option>;
                                    })
                                }
                            </select>
                        </div>

                        <ul className={'tabs'}>
                            {groups &&
                                Object.keys(groups).map(e => {
                                    return <li className={(this.state.current === e ? 'current': '')} onClick={() => this.show(e)} key={'tab_' + e} dangerouslySetInnerHTML={{__html: !!e ? marked(e) : ''}} />;
                                })
                            }
                        </ul>
                    </>
                }


                <div className={'horizontalShadow'} />

                <div className={'tabContent'}>

                    {groups &&
                        Object.keys(groups).map(e => {
                            let group = groups[e];

                            return (
                                <div className={'tab box ' + (this.state.current === e ? 'current': '')} key={'tab_' + e}>
                                    <PartnerSlide images={group} id={e.replace(' ', '-')} forceLoad={this.state.current === e} />
                                </div>
                            );

                        })
                    }

                </div>

            </div>
        );
    }

    componentDidMount() {
        this.setState({
            headline: this.props.data.headline,
            subheadline: this.props.data.subheadline,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tab !== this.props.tab) {
            this.setState({
                current: this.props.tab,
            })
        }
    }
}

export default Partner;
