import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import xlsxParser from 'xlsx-parse-json';

import Header from './components/header.class';

import Start from './components/start.class';
import Gallery from './components/gallery.class';
import Partner from './components/partner.class';
import Slider from './components/slider.class';
import MapBox from './components/map.class';
import Contact from './components/contact.class';
import Loading from "./loading";
import Tabs from "./components/tabs.class";
import Imprint from './components/imprint.class';


const DynamicComponent = (props) => {
    let components =  {
        'Start': <Start {...props} />,
        'Tabs': <Tabs {...props} />,
        'Textbox_mit_Bild': <Slider {...props} />,
        'Galerie': <Gallery {...props} />,
        'Partner': <Partner {...props} />,
        'Karte': <MapBox {...props} />,
        'Kontakt': <Contact {...props} />
    };

    if (!!components[props.view]) {
        return components[props.view];
    } else {
        return null;
    }

};


class AppRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            structure: {},
            menu: {},
            tab: {},
            imprint: false
        };

        this.updateParent = this.updateParent.bind(this);
        this.setTab = this.setTab.bind(this);
        this.showImprint = this.showImprint.bind(this);
        this.hideImprint = this.hideImprint.bind(this);
        this.goTop = this.goTop.bind(this);
    }

    clickAndScroll(to, bottom = null) {
        let container = document.querySelector(to);

        if (!!container) {
            let position = container.offsetTop;

            let topBar = document.querySelector('header');
            let topBarHeight = topBar.getBoundingClientRect();

            let btm = bottom ? (position + container.getBoundingClientRect().height) - window.innerHeight : position - topBarHeight.height;

            if (!!to) {
                window.scroll({
                    top: btm,
                    behavior: 'smooth'
                });
            }
        }

    }

    goTop(to) {
        this.clickAndScroll(to);
    }

    updateParent(key, value) {
        this.setState({
            [key]: value
        });
    }

    setTab(href, tab) {
        this.setState({
            tab: {
                [href]: tab
            }
        });
    }

    showImprint() {
        if (!this.state.imprint) {
            this.setState({
                imprint: true
            });
        } else {
            this.clickAndScroll('#impressum');
        }
    }

    hideImprint() {
        this.setState({
            imprint: false
        });
    }

    render() {

        if (this.state.loading === true) {
            return <Loading />
        } else {

            return (
                <div className={'pageContainer'}>

                    <Header
                        data={this.state.menu}
                        contact={!!this.state.structure['kontakt'] ? this.state.structure['kontakt']['data'].filter(d => d['Typ'] === 'kontaktformular')[0] : null}
                        youtube={!!this.state.structure['kontakt'] ? this.state.structure['kontakt']['data'].filter(d => d['Typ'] === 'Youtube')[0]['Text'] : null}
                        instagram={!!this.state.structure['kontakt'] ? this.state.structure['kontakt']['data'].filter(d => d['Typ'] === 'Instagram')[0]['Text'] : null}
                        goto={this.goTop}
                        setTab={this.setTab}
                        showImprint={() => this.showImprint()} />

                    <div id={'content'}>
                        {
                            Object.keys(this.state.structure).length > 0 &&
                            <>
                                {
                                    Object.keys(this.state.structure).map(e => {
                                        let elm = this.state.structure[e];

                                        if (elm['view'] && elm['view'] !== 'Imprint') {
                                            return <DynamicComponent
                                                id={elm['id']}
                                                view={elm['view']}
                                                data={elm['data']}
                                                key={elm['id']}
                                                setTab={this.setTab}
                                                tab={this.state.tab['#' + elm['id']]}
                                                clickAndScroll={this.clickAndScroll}
                                            />;
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </>
                        }

                        <footer id={'footer'}>

                            <div className={'horizontalLine'} />

                            <div className={'footerInner'}>
                                <div className={'name'}>
                                    FAS {new Date().getFullYear()}
                                </div>

                                <div className={'links'}>
                                    <span onClick={() => this.showImprint()}>Impressum</span>
                                    <span onClick={() => this.showImprint()}>Datenschutz</span>
                                </div>
                            </div>

                        </footer>

                        <Imprint hide={() => this.hideImprint()} show={this.state.imprint} data={this.state.structure['impressum']['data']} />
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        fetch('./xlsx/data.xlsx?' + new Date().getMilliseconds()).then((file) => {
            file.blob().then(blob => {
                xlsxParser
                .onFileSelection(blob)
                .then(data => {

                    let structureData = {};
                    data['Struktur'].map(s => {
                        return structureData[s['ID']] = {
                            id: s['ID'],
                            view: s['View'],
                            data: data[s['Lasche']]
                        }
                    });

                    let references = {
                        headline: '',
                        subheadline: '',
                        images: []
                    };
                    if (!!structureData['referenzen'] && structureData['referenzen']['data'].length) {

                        references.headline = structureData['referenzen']['data'].filter(d => d['Typ'] === 'Hauptueberschrift')[0]['Hauptueberschrift'];
                        references.subheadline = structureData['referenzen']['data'].filter(d => d['Typ'] === 'Unterueberschrift')[0]['Hauptueberschrift'];
                        references.background = structureData['referenzen']['data'].filter(d => d['Typ'] === 'Hintergrundbild')[0]['Hauptueberschrift'];

                        structureData['referenzen']['data'].filter(d => d['Typ'] === 'Bild').map(s => {
                            return references.images.push({
                                image: s['Bild'],
                                headline: s['Hauptueberschrift'],
                                subheadline: s['Unterueberschrift'],
                                details: s['Details'],
                                start: s['Startbild']
                            });
                        });

                        references.images.sort(() => Math.random() - 0.5);
                    }
                    structureData['referenzen']['data'] = references;


                    let start = [];
                    if (!!structureData['start'] && structureData['start']['data'].length) {

                        let refImg = references.images.filter(r => r['start'] === 'Ja');

                        structureData['start']['data'].map((s, k) => {
                            let img = null;
                            let data = null;

                            if (!!s['Bild']) {
                                img = s['Bild'];
                            } else {
                                if (!!refImg[k]) {
                                    img = refImg[k]['image'];
                                    data = refImg[k]
                                }
                            }

                            if (!!img) {
                                return start.push({
                                    image: img,
                                    data: data,
                                    headline: s['Hauptueberschrift'],
                                    subheadline: s['Unterueberschrift']
                                });
                            }  else {
                                return null;
                            }
                        });
                    }
                    structureData['start']['data'] = start;


                    let partner = {
                        headline: '',
                        subheadline: '',
                        tabs: {}
                    };
                    if (!!structureData['partner'] && structureData['partner']['data'].length) {

                        partner.headline = structureData['partner']['data'].filter(d => d['Typ'] === 'Hauptueberschrift')[0]['Text'];
                        partner.subheadline = structureData['partner']['data'].filter(d => d['Typ'] === 'Unterueberschrift')[0]['Text'];

                        structureData['partner']['data'].filter(d => d['Typ'] !== 'Hauptueberschrift' && d['Typ'] !== 'Unterueberschrift').map(s => {
                            if (!partner.tabs[s['Typ']]) {
                                partner.tabs[s['Typ']] = [];
                            }

                            return partner.tabs[s['Typ']].push({
                                logo: s['Bild'],
                                link: s['Text']
                            });
                        });
                    }
                    structureData['partner']['data'] = partner;

                    this.setState({
                        loading: false,
                        structure: structureData,
                        menu: data['Menue'],
                    });
                });
            });
        });
    }

}

export default withRouter(AppRouter);
