import React, {Component} from 'react';

import '../assets/scss/lightbox.scss';
import LazyImage from "./lazyImage.class";

import {ReactComponent as Left} from '../assets/img/svg/left.svg';
import {ReactComponent as Right} from '../assets/img/svg/right.svg';

import {go, goTo} from './sliderFunctions';


let timer = null;
let subTimer = null;

class Lightbox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: [],
            current: this.props.current
        };
    }

    render() {
        let left = '-100%';

        if (this.state.images.length <= 1) {
            left = 0;
        }

        let images = null;

        if (this.state.images.length) {
            images = this.state.images;
        }

        return (
            <div id={'lightbox'} className={'lightbox'} onClick={e => this.props.toggleLightbox(null)} title={'Schließen'}>
                {(!!images && images.length > 1) &&
                    <>
                        <div className={'prevSlide'} onClick={e => {
                            e.stopPropagation();
                            go('prev', 'lightbox', timer, subTimer, this)
                        }} title={'Vorheriges Bild'}>
                            <Left />
                        </div>
                        <div className={'nextSlide'} onClick={e => {
                            e.stopPropagation();
                            go('next', 'lightbox', timer, subTimer, this)
                        }} title={'Nächstes Bild'}>
                            <Right />
                        </div>
                    </>
                }

                <div className={'slideBox'} onClick={e => e.stopPropagation()} title={''}>

                    <div className={'slides'} style={{left:left}}>
                        {
                            (!!images && images.length > 0) &&
                            images.map((image, key) => {

                                let className = '';

                                if (key === this.state.current) {
                                    className += ' current';
                                }

                                if (key === this.state.current-1 && this.state.current-1 >= 0) {
                                    className += ' prev';
                                } else if (key === images.length-1 && this.state.current === 0) {
                                    className += ' prev';
                                }

                                if (key === this.state.current+1 && this.state.current+1 <= images.length-1) {
                                    className += ' next';
                                }

                                return (
                                    <div key={'slide_' + key} className={'slide' + className}>
                                        <div className={'images'}>
                                            <div className={'navigation prev'} onClick={e => {
                                                e.stopPropagation();
                                                go('prev', 'lightbox', timer, subTimer, this)
                                            }} title={'Vorheriges Bild'} />
                                            <div className={'navigation next'} onClick={e => {
                                                e.stopPropagation();
                                                go('next', 'lightbox', timer, subTimer, this)
                                            }} title={'Nächstes Bild'} />
                                            <div className={'navigation close'} onClick={e => {
                                                e.stopPropagation();
                                                this.props.toggleLightbox(null)
                                            }} title={'Schließen'} />


                                            <div key={'large' + key} className={'img'}>
                                                <LazyImage key={'big' + key} dkey={'slider_img_' + key} alt={'img' + key} src={'image.php?file=' + (!!image ? image['image'].replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=1080&width=1920'} forceLoad={true} />
                                            </div>
                                            <div className={'data'}>
                                                <h5 className={'headline'}>{image['headline']}</h5>
                                                <div className={'description'}>{image['subheadline']}</div>
                                                {!!image['details'] &&
                                                    <div className={'details'}>{image['details']}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>


                {(!!images && images.length > 1) &&
                    <div className={'bubbleBox'}>
                        {
                            images.map((slide, key) => {
                                return <div key={'bubble_' + key} className={'bubble' + (this.state.current === key ? ' current' : '')} onClick={e => {
                                    e.stopPropagation();
                                    goTo(key, 'lightbox', timer, subTimer, this)
                                }} />;
                            })
                        }
                    </div>
                }
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            images: this.props.images,
        });
    }
}


export default Lightbox;
