import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import LazyLoad, {forceVisible} from 'react-lazyload';


class LazyImage extends Component {

    load(e) {
        let target = this.refs[this.props.dkey];
        let img = e.target.getAttribute('src');

        setTimeout(() => {
            if (!!target) {
                target.classList.add('loaded');
                target.style.backgroundImage = 'url(' + img + ')';
            }
        }, 200);
    }

    render() {

        let path = '';
        if (process.env.NODE_ENV === 'development') {
            path = window.location.protocol + '//' + window.location.hostname + ':8081/'
        }

        return (
            <LazyLoad>
                <div ref={this.props.dkey} className={'lazyLoad'} />
                <img src={path + this.props.src} onLoad={(e) => this.load(e)} style={{display:'none'}} alt={''} />
            </LazyLoad>
        )

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.forceLoad !== this.props.forceLoad && !!nextProps.forceLoad) {
            forceVisible();
        }

        return this.props.src !== nextProps.src && !!nextProps.src;
    }

}

export default withRouter(LazyImage);
