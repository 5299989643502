import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import LazyImage from './lazyImage.class';

import '../assets/scss/start.scss';

import {ReactComponent as Aluca} from '../assets/img/svg/aluca.svg';

import {shuffle} from './sliderFunctions';


class Start extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: 0,
            prev: null,
            animation:'next',
            delay:10000,

            slides: []
        };

        this.timeout = null;

        this.goTo = this.goTo.bind(this);

        this.parallax();
        this.animate();

        this.slides = this.props.data;
        this.slides = shuffle(this.slides);
    }

    parallax() {
        let _self = this;

        window.addEventListener('scroll', () => {
            let topDistance = window.scrollY;
            let layers = document.querySelectorAll("[data-parallax='true']");

            layers.forEach(layer => {
                let depth = layer.getAttribute('data-depth');
                let movement = -(topDistance * depth);
                let translate3d = 'translate3d(0, ' + movement + 'px, 0)';

                layer.style['-webkit-transform'] = translate3d;
                layer.style['-moz-transform'] = translate3d;
                layer.style['-ms-transform'] = translate3d;
                layer.style['-o-transform'] = translate3d;
                layer.style.transform = translate3d;

                return true;
            });

            let textBoxes = document.querySelector('.startBox .startBoxSlider .textBoxes');

            if (!!textBoxes) {
                textBoxes.style.opacity = 1 - topDistance / (_self.boxHeight/3);
            }

        });
    }

    animate() {

        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.goTo();
            this.animate();
        }, this.state.delay);

    }

    goTo() {
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        let len = this.props.data.length;
        let current, prev;


        prev = this.state.current;

        if (this.state.current === len-1) {
            current = 0;
        } else {
            current = this.state.current + 1;
        }


        this.setState({
            current: current,
            prev: prev
        });

        setTimeout(() => {
            this.setState({
                prev: null
            });
        },400);

    }

    goToIndex(i) {
        if (!!this.timeout) {
            clearTimeout(this.timeout);
        }

        let prev = this.state.current,
            animation = 'next';

        if (i<prev) {
            animation = 'prev';

            this.setState({
                animation: 'stop prev'
            });
        }

        setTimeout(() => {
            this.setState({
                current: i,
                prev: prev,
                animation: animation
            });

            setTimeout(() => {
                this.setState({
                    prev: null,
                    animation: 'next'
                });

                this.animate();
            },400);
        },100);
    }

    render() {

        return (
            <>
                <div id={this.props.id} className={'startBox'}>

                    <div className={'startBoxSlider'}>

                        <div className={'slides'}>

                            <div className={'slidesBox ' + this.state.animation}>
                                {!!this.slides &&

                                this.slides.map((data, key) => {

                                        let cssClass = '';

                                        if (key === this.state.current) {
                                            cssClass = 'currentSlide'
                                        } else if (key === this.state.prev) {
                                            cssClass = 'prevSlide';
                                        }

                                        return (
                                            <div className={'slide ' + cssClass} key={'start_' + key}>
                                                <div className={'imgBox'} data-depth={'0.40'} data-parallax={'true'}>
                                                    <LazyImage dkey={'start_' + key} className={'img'} alt={'start_img_' + key} src={'image.php?file=' + process.env.PUBLIC_URL + data['image'] + '&height=800&width=1920'} />
                                                </div>
                                            </div>
                                        );
                                    })

                                }
                            </div>

                        </div>

                        <div className={'textBoxes'}>
                            {!!this.slides &&
                                this.slides.map((data, key) => {
                                    let cssClass = '';

                                    if (key === this.state.current) {
                                        cssClass = 'current'
                                    }

                                    return (
                                        <div className={'textBox ' + cssClass} key={'start_text_' + key}>

                                            <div className={'headlines'}>
                                                <h1 className="headline ">
                                                    {data['headline']}
                                                </h1>

                                                <h3 className="subheadline ">
                                                    {data['subheadline']}
                                                </h3>
                                            </div>

                                            <a href={'https://aluca.de'} target={'_blank'} className={'alucaBox'}>
                                                <Aluca />
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className={'description'}>
                            {!!this.slides &&
                                this.slides.map((slide, key) => {
                                    let cssClass = '';

                                    if (key === this.state.current) {
                                        cssClass = 'current'
                                    }

                                    let data = slide['data'];

                                    return (
                                        <div className={'descriptions ' + cssClass} key={'descriptions_' + key}>
                                            {!!data &&
                                                <>
                                                    <div className="headline ">
                                                        {data['headline']}
                                                    </div>

                                                    <div className="subheadline ">
                                                        {data['subheadline']}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>


                        <div className={'bubbleBox'}>
                            {!!this.slides &&
                            this.slides.map((data, key) => {
                                return <div key={'bubble_' + key} className={'bubble ' + (this.state.current === key ? 'current' : '')} onClick={() => this.goToIndex(key)} />;
                            })
                            }
                        </div>

                    </div>

                </div>
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.data !== this.props.data || nextState !== this.state);
    }

    componentDidMount() {
        this.box = document.querySelector('.startBoxSlider');
        this.boxPositions = this.box.getBoundingClientRect();
        this.boxHeight = this.boxPositions.height;
        this.textBox = document.querySelector('.startBoxSlider .textBox');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data && !!this.props.data) {
            if (!!this.timeout) {
                clearTimeout(this.timeout);
            }

            let current = document.querySelector('.startBox .currentSlide');
            let p = current.querySelector('div');
            let z = p.querySelector('div');

            if (!!current) {
                z.style.opacity = 0;
            }

            this.setState({
                current: 0,
                prev: null,
                animation:'stop',
            });

            setTimeout(() => {
                this.setState({
                    animation:'next',
                });

                this.animate();

                z.removeAttribute('style');
            }, 1);

            this.box = document.querySelector('.startBoxSlider');
            this.boxPositions = this.box.getBoundingClientRect();
            this.boxHeight = this.boxPositions.height;
            this.textBox = document.querySelector('.startBoxSlider .textBox');

            this.slides = this.props.data;

            this.slides = shuffle(this.slides);
        }
    }

}

export default withRouter(Start);
