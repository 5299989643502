import React, {Component} from 'react';

import '../assets/scss/gallery.scss';
import LazyImage from "./lazyImage.class";

import Lightbox from "./lightbox.class";

import {ReactComponent as Left} from '../assets/img/svg/left.svg';
import {ReactComponent as Right} from '../assets/img/svg/right.svg';

import {go, goTo, shuffle} from './sliderFunctions';


let timer = null;
let subTimer = null;

class Gallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            subheadline: '',
            images: [],
            imagesShuffled: [],
            current: 0,
            lightboxImage: null
        };

        this.toggleLightbox = this.toggleLightbox.bind(this);
    }

    getImages(images) {
        let groups = [];

        let g = 0;

        if (images.length) {
            images.forEach((img, key) => {

                if (!groups[g]) {
                    groups[g] = [];
                }

                img.pos = key;

                groups[g].push(img);

                if (window.innerWidth >= 1024) {
                    if (key % 4 === 3) {
                        g++;
                    }
                } else if (window.innerWidth < 1024 && window.innerWidth > 600) {
                    if (key % 2 === 1) {
                        g++;
                    }
                } else if (window.innerWidth <= 600) {
                    g++;
                }
            });
        }

        return groups;

    }

    toggleLightbox(image = null) {
        if (image !== null) {
            this.setState({
                lightboxImage: image
            });
        } else {
            this.setState({
                lightboxImage: null
            });
        }
    }

    render() {
        let left = '-100%';

        if (this.state.images.length <= 1) {
            left = 0;
        }

        let imageGroups = null;

        if (this.state.images.length) {
            imageGroups = this.state.images;
        }

        let background = this.props.data.background;
        background = (!!background) ? background : null;

        return (
            <>
                <div className={'galleryBoxElm'} id={this.props.id}>

                    <div className={'headlines'}>
                        <h2>
                            {this.state.headline}
                        </h2>

                        <h3>
                            {this.state.subheadline}
                        </h3>
                    </div>

                    <div className="horizontalShadow" />

                    <div className={'sliderOuter'} style={background ? {backgroundImage:'url(assets/' + background + ')'} : {}}>
                        {(!!imageGroups && imageGroups.length > 1) &&
                            <>
                                <div className={'prevSlide'} onClick={() => go('prev', this.props.id, timer, subTimer, this)} title={'Zurück'}>
                                    <Left />
                                </div>
                                <div className={'nextSlide'} onClick={() => go('next', this.props.id, timer, subTimer, this)} title={'Weiter'}>
                                    <Right />
                                </div>
                            </>
                        }

                        <div className={'slideBox'}>

                            <div className={'slides'} style={{left:left}}>
                                {
                                    (!!imageGroups && imageGroups.length > 0) &&
                                    imageGroups.map((images, key) => {

                                        let className = '';

                                        if (key % 2 === 0) {
                                            className = ' left';
                                        }

                                        if (key === 0) {
                                            className += ' current';
                                        } else if ( key === this.state.images.length-1) {
                                            className += ' prev';
                                        } else if (key === 1) {
                                            className += ' next';
                                        }

                                        return (
                                            <div key={'slide_' + key} className={'slide' + className}>
                                                <div className={'images'}>
                                                    <div className={'imgBox'}>
                                                        {
                                                            images.map((i, k) => {
                                                                return (
                                                                    <div key={'large' + k} className={'img'} onClick={e => {
                                                                        if (window.innerWidth >= 800) {
                                                                            this.toggleLightbox(i.pos)
                                                                        }
                                                                    }}>
                                                                        <div className={'image'} title={'Vollbild'}>
                                                                            <LazyImage key={'big' + k} dkey={'slider_img_' + k} alt={'img' + k} src={'image.php?file=' + (!!i ? i['image'].replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=500&width=1000'} />
                                                                        </div>
                                                                        <div className={'data'}>
                                                                            <h5 className={'headline'}>{i['headline']}</h5>
                                                                            <div className={'description'}>{i['subheadline']}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>


                        {(!!imageGroups && imageGroups.length > 1) &&
                            <div className={'bubbleBox'}>
                                {
                                    (!!imageGroups && imageGroups.length > 1) &&
                                    imageGroups.map((slide, key) => {
                                            return <div key={'bubble_' + key} className={'bubble' + (this.state.current === key ? ' current' : '')} onClick={() => goTo(key, this.props.id, timer, subTimer, this)} />;
                                        }
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>

                {this.state.lightboxImage !== null &&
                    <Lightbox current={this.state.lightboxImage} images={this.state.imagesShuffled} toggleLightbox={this.toggleLightbox} />
                }
        </>
        );
    }

    componentDidMount() {
        let images = shuffle(this.props.data.images);

        this.setState({
            images: this.getImages(images),
            imagesShuffled: images,
            headline: this.props.data.headline,
            subheadline: this.props.data.subheadline,
        });
    }
}

export default Gallery;
