import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {GoogleMap, Marker, InfoWindow} from "@react-google-maps/api";
import LazyImage from "./lazyImage.class";

import '../assets/scss/map.scss';

import {ReactComponent as MapPin} from '../assets/img/svg/Pin.svg';
import {ReactComponent as Logo} from '../assets/img/svg/logo_fas_02.svg';


class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bounds: null,
            markers: [],
            showingInfoWindow: false,
            selectedPlace: {},
            zoom: 10,
            center: null,
            load: false,
        };

        this.onReady = this.onReady.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onInfoWindowClose = this.onInfoWindowClose.bind(this);
    }


    onMarkerClick(marker) {
        this.setState({
            showingInfoWindow: true,
            selectedPlace: marker
        });
    }

    clickAndScroll(id) {

        let map = document.querySelector('.mapBox');
        let position = map.offsetTop;

        if (!!map) {
            window.scroll({
                top: position,
                behavior: 'smooth'
            });
        }

        this.setState({
            showingInfoWindow: true,
            selectedPlace: this.state.markers[id],
            center: this.state.markers[id],
            zoom: 16
        });
    }


    onInfoWindowClose() {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                selectedPlace: {}
            })
        }
    }

    makeBounds = (map) => {
        let points = [];
        let bounds = new window.google.maps.LatLngBounds();

        if (!!this.props['data']) {
            this.props['data'].forEach(data => {
                points.push({
                    lat: parseFloat(data['Latitude']),
                    lng: parseFloat(data['Longitude']),
                });
            });
        }


        if (!!points && points.length) {
            for (let i = 0; i < points.length; i++) {
                bounds.extend(points[i]);
            }
        }

        if ( points.length > 1 ) {
            map.fitBounds(bounds);
        }

        this.setState({
            showingInfoWindow: true,
            selectedPlace: points[0]
        });

    };

    onReady = (map) => {

        if (!map) return false;

        const google = window.google;
        let $this = this;

        map.addListener('zoom_changed', function() {
            $this.setState({
                zoom: map.getZoom()
            })
        });

        setTimeout(() => {
            google.maps.event.trigger(map, 'resize');
            this.makeBounds(map);
        }, 300);
    };


    getMapStyle = () => {
        return [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ];
    };

    render() {

        let data = this.props['data'][0];

        return (
            <>

                <div id={this.props.id} className={'mapBox'}>

                    <div className={'horizontalLine'} />

                    <div className={'headlines'}>
                        <h2>
                            {data['Hauptueberschrift']}
                        </h2>

                        <h3>
                            {data['Unterueberschrift']}
                        </h3>
                    </div>

                    <div className="horizontalShadow" />

                    <div className={'location'}>

                        <div className={'left'}>
                            <div className={'image'}>
                                <LazyImage dkey={'location_img'} className={'img'} alt={'location_img'} src={'image.php?file=' + data['Bild'] + '&height=200&width=360'} />
                            </div>

                            <div className={'text'}>
                                <h5>{data['Name']}</h5>
                                <div>
                                    <span>
                                        Telefon: {data['Telefon']}
                                    </span>
                                </div>

                                <div>
                                    <a href={'mailto:' + data['Mail']}>
                                        E-Mail: {data['Mail']}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={'right'}>
                            <address>
                                <h5 className={'company'}>
                                    {data['Firma']}
                                </h5>

                                <div className={'street'}>
                                    {data['Straße']}
                                </div>

                                <div>{data['Stadt']}</div>
                            </address>

                            <MapPin />
                        </div>

                    </div>


                    <div className={'mapBoxInner'}>
                        {this.state.load ?
                            <GoogleMap id={'map'} onLoad={this.onReady} options={{
                                fullscreenControl: true,
                                scrollwheel: false,
                                controlSize: 30,
                                styles: this.getMapStyle()
                            }} containerStyle={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                position: 'relative'
                            }} {...(this.state.center) ? {
                                center: this.state.center,
                            } : {}} zoom={this.state.zoom}>

                                {
                                    Object.keys(this.state.markers).map((i) => {
                                            return <Marker
                                                key={'__' + i}
                                                onClick={() => this.onMarkerClick(this.state.markers[i])}
                                                name={this.state.markers[i]['name']}
                                                icon={{
                                                    scaledSize: new window.google.maps.Size(40, 50),
                                                    url: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" overflow="visible" viewBox="0 0 512 512" fill="%23f39200"><path d="M256 0C161.9 0 85.3 76.6 85.3 170.7c0 28.3 7.1 56.3 20.5 81.1l140.8 254.7c1.9 3.4 5.4 5.5 9.3 5.5 3.9 0 7.5-2.1 9.3-5.5l140.9-254.8c13.4-24.8 20.4-52.8 20.4-81C426.7 76.6 350.1 0 256 0zm0 256c-47.1 0-85.3-38.3-85.3-85.3S209 85.4 256 85.4s85.3 38.3 85.3 85.3S303.1 256 256 256z"/></svg>',
                                                    origin: new window.google.maps.Point(0, 0),
                                                    anchor: new window.google.maps.Point(20, 50)
                                                }}
                                                position={{
                                                    lat: this.state.markers[i].lat,
                                                    lng: this.state.markers[i].lng
                                                }
                                                }>
                                            </Marker>
                                        }
                                    )
                                }

                                {
                                    this.state.showingInfoWindow &&

                                    <InfoWindow key={'_'} onCloseClick={this.onInfoWindowClose} position={this.state.selectedPlace}>
                                        <div className={'infoBox'}>
                                            <Logo />
                                        </div>
                                    </InfoWindow>
                                }

                            </GoogleMap> :

                            <React.Fragment>
                                <div className={'loadMap'}>
                                    <h2>Karte anzeigen</h2>
                                    <h4>BITTE KLICKEN SIE, UM DIE INTERAKTIVE GOOGLE KARTE ANZUZEIGEN.<br />
                                        DABEI WERDEN GERÄTEDATEN MIT GOOGLE AUSGETAUSCHT.</h4>
                                    <button className={'button'} type={'button'} onClick={() => this.setState({load: true})}>Google Maps anzeigen</button>
                                </div>
                            </React.Fragment>

                        }

                    </div>

                </div>
            </>
        );
    }

    componentDidMount() {
        let points = {};

        if (!!this.props['data']) {
            this.props['data'].forEach((data, key) => {
                points[key] = {
                    lat: parseFloat(data['Latitude']),
                    lng: parseFloat(data['Longitude']),
                    data: {
                        name: data['Name'],
                        street: data['Straße'],
                        city: data['Stadt'],
                    },
                };
            });
        }

        if ( Object.keys(points).length > 1 ) {
            this.setState({
                markers: points,
            });
        } else {
            this.setState({
                markers: points,
                center: points[0],
                zoom: 10
            });
        }

    }

}

export default withRouter(Map);
