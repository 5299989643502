import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import LazyImage from './lazyImage.class';

import '../assets/scss/imageSliderTiles.scss';


class ImageTiles extends Component {

    render() {

        let images = !!this.props.images ? this.props.images : [undefined];

        return (
            <div className={'imageBoxInner'}>
                <div className={'imagesBox tiles'}>
                    <div className={'imgBox'}>
                        {
                            images.map((i, k) => {
                                return <div key={'large' + k} className={'img'}>
                                    <LazyImage key={'big' + k} dkey={'slider_img_' + k} alt={'img' + k} src={'image.php?file=' + (!!i ? i.replace(/(\r\n|\n|\r)/gm, "") : 'undefined').trim() + '&height=500&width=800'} forceLoad={this.props.forceLoad} />
                                </div>;
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ImageTiles);
