import 'react-app-polyfill/ie11';
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/_.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';


// missing forEach on NodeList for IE11
if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

smoothscroll.polyfill();

const objectToValuesPolyfill = (object) => {
    return Object.keys(object).map(key => object[key]);
};
Object.values = Object.values || objectToValuesPolyfill;



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
