import React, {Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import {LoadScript} from '@react-google-maps/api'

import AppRouter from './AppRouter.class';
import Loading from './loading'

import '@fontsource/merriweather-sans';
import '@fontsource/roboto';


function App() {
    return (
        <LoadScript id={'script-loader'} googleMapsApiKey={'AIzaSyA16TsCmSRZlT_VazIE4kF_izuvA6ZLMxY'} libraries={['places','geometry']} language={navigator.language || navigator['userLanguage']} loadingElement={<Loading />}>
            <Suspense fallback={<Loading />}>
                <Router>
                    <AppRouter />
                </Router>
            </Suspense>
        </LoadScript>
    );
}

export default App;
